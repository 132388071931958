<template>
    <div class="page page-one" style="min-height: 100vh;font-size: 18px">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('Google Authenticator')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="bs-panel one-card">
                <div class="one-top">
                    <div>{{$t('解除绑定')}}</div>
                    <div><img src="../../assets/pictures/google@2x.png"></div>
                </div>
                <van-steps class="step-card" direction="vertical" :active="active">
                    <van-step>
                        <template #active-icon><div class="step-a">1</div></template>
                        <template #finish-icon><div class="step-a">1</div></template>
                        <template #inactive-icon><div class="step-b">1</div></template>
                        <div class="step-text">{{$t('解除旧身份验证器绑定')}}</div>
                    </van-step>
                    <van-step>
                        <template #active-icon><div class="step-a">2</div></template>
                        <template #finish-icon><div class="step-a">2</div></template>
                        <template #inactive-icon><div class="step-b">2</div></template>
                        <div class="step-text">{{$t('解绑成功')}}</div>
                    </van-step>
                </van-steps>
            </div>
            <div class="shadow-lg bind-panel" v-if="active == 0" >
                <div style="color: #cccccc;text-align: center;padding-top: 5px">
                    {{$t('解除Google身份验证器前需要验证您的账户')}}
                </div>
                <div style="font-size: 14px;margin-top: 20px;display: flex;justify-content: center;flex-direction: column;align-items: center">
                    <van-field v-model="v_email_code" :placeholder="$t('请输入您的邮箱验证码')">
                        <template #button>
                            <div v-if="timeout > 0" style="background-color: #cccccc;padding: 5px;color: #ffffff;min-width: 100px;text-align: center">{{timeout + 's'}}</div>
                            <div @click="send" v-else style="background-color: #FFC100;padding: 5px;color: #ffffff;min-width: 100px;text-align: center">{{$t('获取验证码')}}</div>
                        </template>
                    </van-field>
                    <div style="text-align: left;width: 100%;font-size: 12px;color: #cccccc;padding: 5px 15px">{{$t('请输入')}} {{y_email}} {{$t('收到的验证码')}}</div>
                    <van-field v-model="v_code" style="margin-top: 10px" :placeholder="$t('请输入Google身份验证码')">
                    </van-field>
                </div>
                <div style="display: flex;justify-content: space-between;margin-top: 20px">
                    <van-button @click="url('/code-set')" style="width: 48%;border: 1px solid #0184fa;color: #0184fa">{{$t('上一步')}}</van-button>
                    <van-button type="primary" @click="unbind" style="width: 48%">{{$t('下一步')}}</van-button>
                </div>
            </div>
            <div class="one-card" v-if="active == 1">
                <div style="color: #cccccc;text-align: center;padding-top: 10px;font-size: 14px">
                    {{$t('Google身份验证器解除绑定成功')}}
                </div>
                <div style="padding: 20px 0 10px 0;text-align: center">
                    <img src="../../assets/pictures/pic_sel@2x.png">
                </div>
                <div style="text-align: center;padding-bottom: 20px">
                    <router-link to="/my">
                        <div style="color: #0184fa;margin-left: -10px">{{$t('返回个人中心')}}</div>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                active: 0,
                show: false,
                y_email:'',
                v_email_code: '',
                v_code: '',
                timeout: 0,
                timeout_limit: 60,
                send_flag: false,
            }
        },
        methods: {
            timerInit(s) {
                this.timeout = s;
                let timer = setInterval(() => {
                    if (this.timeout <= 0) {
                        clearInterval(timer);
                        localStorage.removeItem("last_timer");
                        return;
                    }
                    this.timeout -= 1;
                    localStorage.setItem("last_timer", this.timeout);
                }, 1000);
            },
            send(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }
                let that = this;
                this.send_flag = true;
                this.$axios.post('/send_mail',
                    this.$qs.stringify({email: this.y_email})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        that.timerInit(this.timeout_limit);
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            goStep(val){
                this.active = val;
            },
            onClickLeft() {
                this.url('/code-set')
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            unbind(){
                this.$toast.loading();

                if(this.send_flag){
                    return ;
                }

                if(this.v_email_code == ''){
                    this.$toast.fail(this.$t('请输入您的邮箱验证码'));
                    return ;
                }
                if(this.v_code == ''){
                    this.$toast.fail(this.$t('请输入Google身份验证码'));
                    return ;
                }
                let that = this;
                this.send_flag = true;
                let data = helper.encrypt(JSON.stringify({ve:this.v_email_code, c: this.v_code}));
                this.$axios.post('/gcode/unbind',
                    this.$qs.stringify({data: data})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        that.active = 1;
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            isBind(){
                this.$axios.get('/gcode/is_bind').then((res) => {
                    if (res.data.code == 0) {
                        if(res.data.data.is_bind == 0){
                            this.url('/code-set');
                        }
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            getMailInfo(){
                this.$axios.get('/get_mail_info').then((res) => {
                    if (res.data.code == 0) {
                        this.y_email = res.data.data.email;
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
        },
        mounted() {
            localStorage.setItem('aqzx', '1');
            this.isBind();
            this.getMailInfo();
        }

    }
</script>
